<template>
  <TopBar />
  <NavBar />
  <DefaultBanner title="Password Reset" :class="['about-us-banner-bg']" />
  <div class="w-100 lg:pt-20 relative overflow-hidden">
    <div
      class="
        absolute
        z-0
        left-0
        right-0
        top-0
        bottom-0
        flex flex-row
        justify-between
        items-center
      "
    >
      <img
        class="lg:w-auto w-full absolute left-0 top-0 hidden lg:block"
        src="/images/map-left.png"
      />
      <img
        class="lg:w-auto w-1/2 absolute left-0 top-0 lg:hidden"
        src="/images/map-right.png"
      />
    </div>
    <div
      class="
        container
        mx-auto
        lg:px-0
        px-5
        py-6
        z-10
        relative
        flex flex-row
        items-stretch
        lg:mb-20
        mb-12
      "
    >
      <div class="flex flex-col w-full md:w-1/2 mx-auto">
        <div class="relative mb-8 text-left">
          <input
            v-model="loginEmail"
            class="border p-4 w-full pl-12"
            placeholder="Email"
          />
          <div
            class="
              absolute
              left-0
              top-0
              bottom-0
              flex
              justify-center
              items-center
              w-12
            "
          >
            <i class="icon email-outline small"></i>
          </div>
        </div>
        <div class="relative mb-8">
          <input
            v-model="newPassword"
            class="border p-4 w-full pl-12"
            placeholder="Password"
            type="password"
          />
          <div
            class="
              absolute
              left-0
              top-0
              bottom-0
              flex
              justify-center
              items-center
              w-12
            "
          >
            <i class="icon password small"></i>
          </div>
        </div>
        <div class="relative mb-8">
          <input
            v-model="confirmPassword"
            class="border p-4 w-full pl-12"
            placeholder="Confirm Password"
            type="password"
          />
          <div
            class="
              absolute
              left-0
              top-0
              bottom-0
              flex
              justify-center
              items-center
              w-12
            "
          >
            <i class="icon password small"></i>
          </div>
        </div>
        <div class="relative">
          <span
            v-if="errors['general']"
            class="text-red-700 text-xs leading-tight block mb-8"
            >{{ errors["general"] }}</span
          >
          <div
            v-if="messages['info']"
            class="
              text-white
              bg-green-700
              text-sm
              leading-tight
              block
              mb-8
              p-4
              text-center
            "
          >
            <div class="block">{{ messages["info"] }}</div>
            <div class="block">
              <a
                class="
                  p-2
                  inline-block
                  mt-4
                  px-8
                  bg-white
                  text-green-700
                  uppercase
                "
                href="/"
                >Continue</a
              >
            </div>
          </div>
        </div>
        <button
          @click.prevent="updatePassword"
          class="bg-blue uppercase text-white p-4 mb-8"
        >
          Change Password
        </button>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import DefaultBanner from "../components/DefaultBanner.vue";
import Footer from "../components/Footer.vue";
import NavBar from "../components/NavBar.vue";
import TopBar from "../components/TopBar.vue";

export default {
  name: "Password Reset Form",
  components: {
    TopBar,
    NavBar,
    DefaultBanner,
    Footer,
  },
  data() {
    return {
      loginEmail: "",
      newPassword: "",
      confirmPassword: "",
      errors: {},
      messages: {},
      token: "",
    };
  },
  mounted() {
    this.loginEmail = this.$route.query.email;
    this.token = this.$route.params.token;
    console.log(this.token);
  },
  methods: {
    validateForm() {
      this.initializeErrors();
      let isValid = true;
      if (!this.loginEmail) {
        this.setInvalidFormField("general", "Email id is required");
        return false;
      }
      if (!this.newPassword) {
        this.setInvalidFormField("general", "Password is required");
        return false;
      }
      if (this.newPassword != this.confirmPassword) {
        this.setInvalidFormField(
          "general",
          "Password & Confirm password must match!"
        );
        return false;
      }
      return isValid;
    },
    setInvalidFormField(field, message) {
      this.errors[field] = message;
    },
    initializeErrors() {
      this.errors = {};
      this.messages = {};
    },
    updatePassword() {
      if (!this.validateForm()) {
        return;
      }
      const params = {
        token: this.token,
        email: this.loginEmail,
        password: this.newPassword,
        password_confirmation: this.confirmPassword,
      };

      this.$store.dispatch("setIsLoading", true);
      this.$store.dispatch("auth/resetPassword", params).then(
        (data) => {
          console.log(data);
          this.$store.dispatch("setIsLoading", false);
          this.loginEmail = "";
          this.newPassword = "";
          this.confirmPassword = "";
          this.messages["info"] = "Password has been reset successfully!";
        },
        (error) => {
          this.$store.dispatch("setIsLoading", false);
          if (error.response) {
            console.log(error.response);
            if (error.response.data.message) {
              this.errors["general"] = error.response.data.message;
            }
            if (!this.errors.general) {
              this.errors["general"] = error.response.statusText;
            }
          } else {
            console.log(error.request);
            this.errors["general"] = "Sorry! Something went wrong! ";
          }
        }
      );
    },
  },
};
</script>

<style></style>